import { Certification } from '@/features/courses';
import { useAppSelector } from '@/store/hooks';

export const Certifications = () => {
  const { course } = useAppSelector(state => state.course);

  return (
    <section className="para_que_sirve container py-5">
      <div className="row">
        <div className="col-md-6">
          <header className="row mb-5">
            <h2 className={'mb-0'}>¿Para qué sirve la certificación?</h2>
          </header>
          <div className="accordion certificacion_accordion pe-sm-5" id="certificacion_accordion">
            {course.certifications &&
              course.certifications.map((certification: Certification, i) => {
                return (
                  <div key={'certification_' + i} className="accordion-item py-2 px-0">
                    <h2 className="accordion-header" id={'certification_heading' + i}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={'#certification_collapse' + i}
                        aria-controls={'certification_collapse' + i}>
                        {certification.header}
                      </button>
                    </h2>
                    <div
                      id={'certification_collapse' + i}
                      className="accordion-collapse collapse"
                      aria-labelledby={'certification_heading' + i}
                      data-bs-parent="#certificacion_accordion">
                      <div className="accordion-body">
                        <p>{certification.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-md-6">
          <figure className="certificación d-flex justify-content-center align-items-center">
            <img
              src="/img/certificado.png"
              alt="Para qué sirve la certificación"
              className="img-fluid"
            />
          </figure>
        </div>
      </div>
    </section>
  );
};
