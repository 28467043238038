// eslint-disable-next-line no-restricted-imports
import '@/features/course/Course.css';
import { useAppSelector } from '@/store/hooks';
import { Link } from 'react-router-dom';
import { mode } from '../utils/mode';
import moment from 'dayjs';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

export const LogoDiplomatura = ({ tipo }) => {
  let imagen;
  let ancho;
  let alt;
  if (tipo == 'fcefyn') {
    imagen = '/img/logo_fcefyn.svg';
    ancho = '114px';
    alt = 'Curso certificado por la FCEFYN';
  } else if (tipo == 'unc') {
    imagen = '/img/home-new/logo-UNC.png';
    ancho = '114px';
    alt = 'Curso certificado por la UNC';
  } else if (tipo == 'usil') {
    imagen = '/img/Lodo_USIL.svg';
    ancho = '114px';
    alt = 'Curso certificado por la USIL';
  }

  return (
    <figure>
      <img src={imagen} width={ancho} className="img-fluid" alt={alt} />
    </figure>
  );
};
